import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faPlay } from "@fortawesome/free-solid-svg-icons";

const TvCard = ({ data, highlighted }) => {
  const { name, processedName, categories, tags, url, bannerImage } = data;
  return (
    <Link to={"/jagatv/" + processedName}>
      <div className="singleTM card-container tickets-card">
        <div
          className="tm_img tickets-card-bg"
          style={{
            backgroundImage: `url('${
              bannerImage
                ? `${process.env.GATSBY_API_BASE_URL}/${bannerImage.path}`
                : ""
            }')`,
          }}
        >
          <div className="tm_overlay"></div>
          <div className="detail_TM align-items-start justify-content-between">
            <Row className="ms-0 me-2 align-items-start w-100 text-center">
              <Col className="col-auto me-auto">
                <div className="tv-tags">{categories[0]}</div>
              </Col>
              {highlighted && highlighted == 1 ? (
                <Col className="col-auto ms-auto">
                  <div className="tv-tags-premium d-flex align-items-center">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row className="mx-auto">
              <div className="tv-play">
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </Row>
            <Row className="mx-auto text-center tv-news-name">{name}</Row>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TvCard;
